.counter-title {
	text-align: center;
	font-weight: 600;
	font-size: 14px;
	height: 25px;
}
.counter-count {
	text-align: center;
	font-size: 16px;
}

.headerWrapper {
	position: relative;
}
.headerWrapper .header-container {
	background-color: #ffffff;
	border-radius: 6px;
	padding: 20px 30px;
	border: 1px solid #d5d5d5;
	display: flex;
}
.header-container .team-nav {
	width: 62%;
	border-right: 1px solid #d5d5d5;
	padding-right: 15px;
}
.header-container .team-nav .item-nav {
	display: flex;
	justify-content: space-between;
}

.header-container .team-name-nav {
	width: 16%;
	border-right: 1px solid #d5d5d5;
}
.header-container .team-name-nav .item-nav {
	display: flex;
	justify-content: space-between;
}
.header-container .team-name-nav .item-nav .item {
	margin: 0 auto;
}
.header-container .team-name-nav .details-wrapper {
	cursor: pointer;
}
.header-container .team-name-nav .details-wrapper p {
	line-height: 1;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}
.header-container .details-wrapper p.number {
    font-size: 21px;
    font-weight: 700;
    margin-top: 5px;
    color: #00a94f;
}

.header-container .team-name-nav .details-form {
	margin: 0 auto;
	width: 80%;
}
.header-container .team-name-nav .details-form input {
	width: 100%;
	height: 30px;
}
.header-container .team-name-nav .details-form .team-name-btn {
	width: 100px;
	height: 30px;
	color: white;
	background-color: #00a94f;
	border-radius: 5px;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
	margin: 0 auto;
}

.header-container .result-nav {
	width: 22%;
}
.header-container .result-nav .item-nav {
	display: flex;
	justify-content: center;
	align-items: center;
}
.header-container .result-nav .item-nav .item {
	display: flex;
	justify-content: center;
	align-items: center;
}
.header-container .result-nav .item-nav .item p {
	font-weight: 700;
	font-size: 28px;
	margin: 0;
	margin-left: 15px;
}
.header-container .result-nav .item-nav .item + .item {
	margin-left: 30px;
}


.logWrapper {
	position: absolute;
    right: 50px;
    top: 25px;
	width: 50px;
    height: 50px;
}
.log-item {
	width: 40px;
	margin: 0 auto;
	cursor: pointer;
}


.deleteWrapper {
	position: absolute;
    right: 0px;
    top: 25px;
	width: 50px;
    height: 50px;
}
.delete-item {
	width: 40px;
	margin: 0 auto;
}


.files-section {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	cursor: pointer;
}
.files-section svg {
	transform: rotate(90deg);
	margin-left: 5px;
}

.files-container {
	position: absolute;
    right: 0px;
    top: 25px;
    min-width: 200px;
    min-height: 30px;
    z-index: 100;
    background-color: #FFF;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border: 1px solid #1B5B97;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
	overflow: hidden;
}
.file-row {
	border-bottom: 1px solid #1B5B97;
	padding: 5px;
}
.file-row .file-link,
.file-row .file-link a {
	width: 100%;
}
.file-row:hover {
	background-color: #F1F1F1;
}