.boardContainer {
	max-width: 1042px;
	width: 100%;
	margin: 0 auto;
	position: relative;
}

.boardPageWrapper {
	margin-top: 20px;
	width: 100%;
	height: 640px;
	position: relative;
	padding: 10px 20px; /*45px 20px;*/
}
.boardPageWrapper.activeProfile {
	padding: 0;
}
.boardPageWrapper .main-image-wrapper {
/*.boardPageWrapper .image-wrapper {*/
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: -1;
}
.boardPageWrapper .image-wrapper img {
	width: 100%;
	height: 100%;
}

.boardWrapper {
	width: 100%;
	height: 545px;
	border-left: 1px solid #ffffff;
	border-bottom: 1px solid #ffffff;
	display: flex;
	position: relative;
	margin-top: 40px;
}
/*.boardWrapper::before {
	content: 'POTENTIAL';
	position: absolute;
	top: -27px;
	left: 0;
	color: #ffffff;
	font-size: 12px;
	font-weight: 600;
}
.boardWrapper::after {
	content: 'PERFORMANCE';
	position: absolute;
	bottom: -27px;
	right: 0;
	color: #ffffff;
	font-size: 12px;
	font-weight: 600;
}*/

.potential-arrow {
	position: absolute;
	top: 0;
	left: -7px;
	line-height: 1;
}
.potential-arrow svg {
	transform: rotate(-90deg);
}
.performer-arrow {
	position: absolute;
	right: 0;
	bottom: -8.5px;
	line-height: 1;
}

.board-wrapper.threeColumns {
	width: 33.33%;
}
.board-wrapper.fourColumns {
	width: 33.33%;
}
.board-wrapper:nth-of-type(1) {
	display: flex;
	align-items: flex-end;
}
.board-wrapper.threeColumns:nth-of-type(1) .cards {
	justify-content: flex-end;
}
.board-wrapper:nth-of-type(2) {
	display: flex;
	align-items: center;
	border-right: 3px dashed #ffffff;
	border-left: 3px dashed #ffffff;
}
.board-wrapper:nth-of-type(2) .cards {
	justify-content: center;
}
.board-wrapper:nth-of-type(3) {
	display: flex;
	align-items: flex-start;
}
.board-wrapper:nth-of-type(3) .cards {
	justify-content: flex-start;
}

.board-wrapper.threeColumn .cards {
	height: 307px;
	display: flex;
	flex-wrap: wrap;
	padding: 0 30px;
	margin-bottom: 20px;
}
.board-wrapper.fourColumn .cards {
	height: 307px;
	display: flex;
	flex-wrap: wrap;
	padding: 0 8px;
	margin-bottom: 20px;
}

.board-wrapper .cards > div {
	margin-right: 10px;
	/*margin-bottom: 25px;*/
}
/* .board-wrapper.threeColumns .cards > div:nth-of-type(3n + 3) {
	margin-right: 0px;
}
.board-wrapper.threeColumns .cards > div:nth-last-of-type(-n + 4) {
	margin-bottom: 10px;
}

.board-wrapper.fourColumns .cards > div:nth-of-type(3n + 4) {
	margin-right: 0px;
}
.board-wrapper.fourColumns .cards > div:nth-last-of-type(-n + 4) {
	margin-bottom: 10px;
} */

.threeColumn .cardsWrapper > div:nth-of-type(3n + 3) {
	margin-right: 0px;
}
.threeColumn .cardsWrapper > div:nth-of-type(-n + 4) {
	margin-bottom: 10px;
}

.fourColumn .cardsWrapper > div:nth-of-type(4n + 4) {
	margin-right: 0px !important;
}
.fourColumn .cardsWrapper > div:nth-of-type(-n + 4) {
	margin-bottom: 10px;
}


.board-wrapper .cards .numbers {
	display: flex;
	justify-content: space-between;
	margin: 0;
}
.board-wrapper .cards .numbers p {
	color: #ffffff;
	width: 70px;
	text-align: center;
	margin: 0;
	margin-right: 10px;
	font-size: 10px;
	font-weight: 500;
	line-height: 1;
	display: inline-block;
}

.cardsWrapper {
	height: 307px;
	display: flex;
	flex-wrap: wrap;
	padding: 0 30px;
	margin-bottom: 20px;
}
.cardsWrapper > div {
	margin-right: 10px;
    /*margin-bottom: 25px;*/
}
.cardsWrapper .numbers {
    display: flex;
    justify-content: space-between;
	margin: 0;
}
.cardsWrapper .numbers p {
	color: #ffffff;
	width: 70px;
	text-align: center;
	margin: 0;
	margin-right: 10px;
	font-size: 10px;
	font-weight: 500;
	line-height: 1;
	display: inline-block;
}

.cardWrapper {
	width: 70px;
	height: 79px;
	border-radius: 6px;
	padding: 0 6px;
	position: relative;
}
.cardWrapper .background {
	background: #002C59;
	border: 0.9px solid #ffffff;
	opacity: 0.54;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	border-radius: 6px;
}
.cardWrapper .card-item {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
}
.cardWrapper .card-item p {
	font-size: 8px;
	font-weight: 700;
	text-align: center;
	color: #ffffff;
	position: relative;
	z-index: 2;
}

.characterWrapper {
	position: absolute;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	top: 0;
	opacity: 1;
	z-index: 4;
}
.characterWrapper div {
	cursor: grab;
	position: relative;
	z-index: 3;
}
.characterWrapper div + div {
	margin-left: -8px;
}
.characterWrapper img {
	width: 25px;
	height: 25px;
}

.avatarWrapper {
	width: 50px;
	height: 50px;
	background: #005b97;
	border-radius: 50%;
}
.avatarWrapper img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}
.characterWrapper .avatarWrapper {
	width: 30px !important;
	height: 30px !important;
	box-shadow: 0px 2px #ccc;
}

.languagesList {
	position: absolute; 
	width: 100px;
	/*height: 80px;*/
	background-color: #F1F1F1;
	border: 1px solid #000;
	bottom: 260px;
	left: -6px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	padding-top: 10px;
	padding-bottom: 10px;
	max-height: 160px;
	overflow-y: auto;
    overflow-x: hidden;
}
.languagesList .language {
	/*min-height: 30px;*/
	cursor: pointer;
	text-align: center;
	white-space: pre-wrap;
	line-height: 24px;
	margin-bottom: 5px;
}
.languagesList .language:hover {
	background-color: #E1E1E1;
}
.languagesList::-webkit-scrollbar {
	width: 6px;
  }
  
  /* Track */
  .languagesList::-webkit-scrollbar-track {
	background: #f1f1f1;
  }
  
  /* Handle */
  .languagesList::-webkit-scrollbar-thumb {
	background: #888;
  }
  
  /* Handle on hover */
  .languagesList::-webkit-scrollbar-thumb:hover {
	background: #555;
  }
  
.potential-text {
	position: absolute;
	top: -27px;
	left: 0;
	color: #ffffff;
	font-size: 12px;
	font-weight: 600;
}
.performance-text {
	position: absolute;
    bottom: -27px;
    right: 0;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
}
