@media (max-width: 767px) {
	.boardWrapper {
		display: block;
		height: auto;
	}

	.board-wrapper {
		width: 100%;
	}
	.board-wrapper:nth-of-type(1) {
		display: block;
	}
	.board-wrapper:nth-of-type(1) .cards {
		justify-content: center;
	}
	.board-wrapper:nth-of-type(2) {
		display: block;
		border-right: none;
		border-left: none;
		border-top: 3px dashed #ffffff;
		border-bottom: 3px dashed #ffffff;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.board-wrapper:nth-of-type(3) {
		display: block;
	}
	.board-wrapper:nth-of-type(3) .cards {
		justify-content: center;
	}

	.board-wrapper .cards {
		padding: 0 15px;
		max-width: 333px;
		margin: auto;
	}
}