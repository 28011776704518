.profile-container {
	position: relative;
	height: 615px;
    border: 1px solid #d5d5d5;
    border-radius: 7px;
	overflow-y: auto;
	background-color: #FFFFFF;
}
.profile-status-wrapper {
	padding: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
}
.profile-table table {
	width: 100%;
}
.profile-table thead {
	background-color: lightgray;
}
.profile-table.Gold thead {
	background-color: rgb(218, 175, 56);
}
.profile-table.Green thead {
	background-color: rgb(22, 185, 0);
}
.profile-table.Blue thead {
	background-color: #005b97;
}
.profile-table.Red thead {
	background-color: rgb(219, 22, 22);
}
.profile-table.White thead {
	background-color: #FFFFFF;
}

.profile-table table {
	border: 2px solid #000;
	border-collapse: collapse;
}
.profile-table table td, .profile-table table th {
	padding: 5px;
	border: 1px solid #ddd;
}



.flex-profile {
	display: flex;
    height: 615px;
    border: 1px solid #d5d5d5;
    border-radius: 7px;
}

.column {
	padding: 30px;
}

.column h3 {
	margin-top: 0;
}

.column h4{
    margin: 30px 0 10px 0;
}

.column .text {
	font-weight: 400;
	font-size: 14px;
}

.column .text.mt-50 {
    margin-top: 50px;
}
.column .text.mt-0 {
	margin-top: 0;
}

.column .text.bold {
	font-weight: 500;
}

.firstColumn {
	background-color: #fff;
	width: 430px;
}

.secondColumn,
.thirdColumn {
	width: calc(50% - 430px/2);
	background-color: #f3faff;
	white-space: pre-wrap;
}

.thirdColumn ul {
	list-style-type: none;
	margin: 0;
	padding-inline-start: 5px;
}

.thirdColumn ul > li {
    text-indent: -11px;
    margin-bottom: 8px;
}

.thirdColumn ul > li:before {
    content: "- ";
}

.close-button{
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 50%;
	background-color: transparent;
	width: 24px;
	height: 24px;
	border: 1px solid #000;
	margin: 5px;
}


