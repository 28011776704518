#eventTeamTable {
	border-spacing: 0px;
	background-color: rgba(255, 255, 255, 0.5);
}
#eventTeamTable td, #eventTeamTable th {
	border: 1px solid #ddd;
	padding: 8px;
}


.closeIcon {
	width: 30px;
	height: 30px;
	border-radius: 15px;
	border: 1px solid #FFF;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFF;
	cursor: pointer;
}
.log-actions {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}


.row_player_1 {
	background-color: rgba(169, 148, 0, 0.5);
}
.row_player_4 {
	background-color: rgba(0, 169, 79, 0.5);
}
.row_player_2 {
	background-color: rgba(27, 91, 151, 0.5);
}
.row_player_5 {
	background-color: rgba(210, 11, 71, 0.5);
}