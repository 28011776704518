.development-header {
	color: #ffffff;
	padding-left: 1.5em;
	padding-right: 1.5em;
	font-weight: 600;
	font-size: 1.3em;
	display: flex;
	justify-content: space-between;
	/*margin-top: -1em;*/
}
.development-header span {
	cursor: pointer;
}
.development-header span:hover {
	text-decoration: underline;
}

.info-container {
	display: flex;
	flex-wrap: nowrap;
	height: 25em;
	margin-top: 2em;
}

.people-container {
	width: 35%;
	background-color: #002858;
	border: 1px solid #fff;
	border-radius: 7px;
	margin-right: 1em;
	height: inherit;
	color: #fff;
	max-height: 100%;
	overflow-y: scroll;
}

.player-grid {
	cursor: pointer;
	padding: 10px;
	margin: 10px;
	display: grid;
	grid-template-columns: 60px auto;
	border-radius: 5px;
}

/* width */
.people-container::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	margin: 40px 5px;
	/* box-shadow: inset 0 0 2px grey; */
	border-radius: 50%;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #e3e3e6;
	border-radius: 10px;
}

.player-info {
	font-size: 1rem;
	font-weight: 300;
}

.player-title {
	font-weight: 200;
	font-size: 0.8rem;
}

.selected {
	background-color: #fdba0b;
	border-radius: 5px;
}
.disabled {
	background-color: #cccccc;
	opacity: 0.8;
	border-radius: 5px;
}

.title {
	margin: 1.5em 1.5em 0 1.5em;
	font-size: 1.2em;
}

.course-list {
	background-color: #002858;
	height: inherit;
	border-radius: 7px;
	width: 65%;
	overflow-y: auto;
}

.purchase-button {
	background-color: #fdba0b;
	width: 100%;
	height: 4em;
	margin-top: 3em;
	border: none;
	border-radius: 10px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
}

.purchase-button:disabled {
	background-color: lightgray;
}

/* modal */

.modal-title {
	color: #005B98;
	text-align: center;
}

.button-div{
   display: flex;
   justify-content: space-between;
   padding: 20px;
}


.modal-button{
    color: #fff;
    background-color: #005B98;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    width: 48%;
    height: 3rem;
    text-align: center;
}

.modal-button.red{
    background-color: #D20B47;
}

/* course section */
.courses-section{
	display: grid;
	grid-template-columns: 350px 350px;
	color: #fff;
	background-color: #002858;
	border-radius: 5px;
}

.categories-list{
	border-right: 1px solid #4C6B8C;
	padding: 1rem;
}

.categories-list h2{
	margin: 0;
}
.categories-list h4{
	margin: 10px 0;
}
.course-list{
	width: 100%;
	padding: 1rem;
}

/*  */

.time, .currency, .course-price{
	display: flex;
    justify-content: center;
    align-items: center;
}
 .time p, .currency p{
	display: inline;
	font-weight: 700;
    font-size: 16px;
    margin: 0;
    margin-left: 15px;
}

.time{
	margin-right: 22px;
}

/*  */
.category, .course{
	padding: 0.65rem;
    padding-bottom: 0;
}

.course-list .main-title{
	margin-bottom: 15px;
}

/*  */
.total-spent, .spent-stats{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	min-height: 50px;
}

.total-spent p{
	display: inline;
	font-weight: 700;
    font-size: 16px;
	margin: 0 15px;
}


.categories-list .time-wrapper {
	display: flex;
	align-items: center;
}
.categories-list .money-wrapper {
	display: flex;
	align-items: center;
}