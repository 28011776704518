.flyingObj {
    background: #f7f7f7;
    width: 38px;
    height: 38px;
    border-radius: 12px;
    position: absolute;
    top: 23px; /*calc(100% - 20px);*/
    left: 20px; /*calc(100% - 25px);*/
    z-index: 106;
    display: flex;
    justify-content: center;
    align-items: center;
}
.flyingObj p{
    margin: 0;
    color: #1B5B97;
    font-weight: 700;
    font-size: 16px;
}

.flyingObj.player_6 {
    animation: moveToPlayer6 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_6_motivation {
    animation: moveToPlayer6Motivation 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_6_skills {
    animation: moveToPlayer6Skills 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_6_time {
    animation: moveToPlayer6Time 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_6_amount {
    animation: moveToPlayer6Amount 1s;
    animation-fill-mode: forwards;
}
@keyframes moveToPlayer6 {
    to {
        left: 20px;
        top: 226px;
    }
}
@keyframes moveToPlayer6Motivation {
    to {
        left: 331px;
        top: 234px;
    }
}
@keyframes moveToPlayer6Skills {
    to {
        left: 147px;
        top: 234px;
    }
}
@keyframes moveToPlayer6Time {
    to {
        left: 241px;
        top: 170px;
    }
}
@keyframes moveToPlayer6Amount {
    to {
        left: 321px;
        top: 170px;
    }
}




.flyingObj.player_1 {
    animation: moveToPlayer1 1s;
	animation-fill-mode: forwards;
}
.flyingObj.player_1_motivation {
    animation: moveToPlayer1Motivation 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_1_skills {
    animation: moveToPlayer1Skills 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_1_time {
    animation: moveToPlayer1Time 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_1_amount {
    animation: moveToPlayer1Amount 1s;
    animation-fill-mode: forwards;
}
@keyframes moveToPlayer1 {
    to {
        left: 20px;
        top: 316px;
    }
}
@keyframes moveToPlayer1Motivation {
    to {
        left: 331px;
        top: 434px;
    }
}
@keyframes moveToPlayer1Skills {
    to {
        left: 147px;
        top: 434px;
    }
}
@keyframes moveToPlayer1Time {
    to {
        left: 241px;
        top: 370px;
    }
}
@keyframes moveToPlayer1Amount {
    to {
        left: 321px;
        top: 370px;
    }
}




.flyingObj.player_4 {
    animation: moveToPlayer4 1s;
	animation-fill-mode: forwards;
}
.flyingObj.player_4_motivation {
    animation: moveToPlayer4Motivation 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_4_skills {
    animation: moveToPlayer4Skills 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_4_time {
    animation: moveToPlayer4Time 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_4_amount {
    animation: moveToPlayer4Amount 1s;
    animation-fill-mode: forwards;
}
@keyframes moveToPlayer4 {
    to {
        left: 20px;
        top: 406px;
    }
}
@keyframes moveToPlayer4Motivation {
    to {
        left: 331px;
        top: 634px;
    }
}
@keyframes moveToPlayer4Skills {
    to {
        left: 147px;
        top: 634px;
    }
}
@keyframes moveToPlayer4Time {
    to {
        left: 241px;
        top: 570px;
    }
}
@keyframes moveToPlayer4Amount {
    to {
        left: 321px;
        top: 670px;
    }
}




.flyingObj.player_2 {
    animation: moveToPlayer2 1s;
	animation-fill-mode: forwards;
}
.flyingObj.player_2_motivation {
    animation: moveToPlayer2Motivation 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_2_skills {
    animation: moveToPlayer2Skills 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_2_time {
    animation: moveToPlayer2Time 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_2_amount {
    animation: moveToPlayer2Amount 1s;
    animation-fill-mode: forwards;
}
@keyframes moveToPlayer2 {
    to {
        left: 20px;
        top: 496px;
    }
}
@keyframes moveToPlayer2Motivation {
    to {
        left: 331px;
        top: 834px;
    }
}
@keyframes moveToPlayer2Skills {
    to {
        left: 147px;
        top: 834px;
    }
}
@keyframes moveToPlayer2Time {
    to {
        left: 241px;
        top: 770px;
    }
}
@keyframes moveToPlayer2Amount {
    to {
        left: 321px;
        top: 770px;
    }
}




.flyingObj.player_5 {
    animation: moveToPlayer5 1s;
	animation-fill-mode: forwards;
}
.flyingObj.player_5_motivation {
    animation: moveToPlayer5Motivation 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_5_skills {
    animation: moveToPlayer5Skills 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_5_time {
    animation: moveToPlayer5Time 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_5_amount {
    animation: moveToPlayer5Amount 1s;
    animation-fill-mode: forwards;
}
@keyframes moveToPlayer5 {
    to {
        left: 20px;
        top: 586px;
    }
}
@keyframes moveToPlayer5Motivation {
    to {
        left: 331px;
        top: 1031px;
    }
}
@keyframes moveToPlayer5Skills {
    to {
        left: 147px;
        top: 1031px;
    }
}
@keyframes moveToPlayer5Time {
    to {
        left: 241px;
        top: 970px;
    }
}
@keyframes moveToPlayer5Amount {
    to {
        left: 321px;
        top: 970px;
    }
}





.flyingObj.player_3 {
    animation: moveToPlayer3 1s;
	animation-fill-mode: forwards;
}
.flyingObj.player_3_motivation {
    animation: moveToPlayer3Motivation 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_3_skills {
    animation: moveToPlayer3Skills 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_3_time {
    animation: moveToPlayer3Time 1s;
    animation-fill-mode: forwards;
}
.flyingObj.player_3_amount {
    animation: moveToPlayer3Amount 1s;
    animation-fill-mode: forwards;
}
@keyframes moveToPlayer3 {
    to {
        left: 20px;
        top: 676px;
    }
}
@keyframes moveToPlayer3Motivation {
    to {
        left: 331px;
        top: 1228px;
    }
}
@keyframes moveToPlayer3Skills {
    to {
        left: 147px;
        top: 1228px;
    }
}
@keyframes moveToPlayer3Time {
    to {
        left: 241px;
        top: 1170px;
    }
}
@keyframes moveToPlayer3Amount {
    to {
        left: 321px;
        top: 1170px;
    }
}




@keyframes move {
    100%{
        position: fixed;
        right: 100%;
    }
}
@-moz-keyframes move {
    /* Firefox */
    100%{
        position: fixed;
        right: 100%;
    }
}
@-webkit-keyframes move {
    /* Safari and Chrome */
    100%{
        position: fixed;
        right: 100%;
    }
}
@-o-keyframes move {
    /* Opera */
    100%{
        position: fixed;
        right: 100%;
    }
}



.flyingObj.organization,
.flyingObj.teamLead,
.flyingObj.team,
.flyingObj.time,
.flyingObj.amount {
	animation-fill-mode: forwards !important;
}













/************* Impact CSS ***************/
.impact {
	font-size: 11px;
	margin-left: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
}
.impact.up {
	line-height: 20px;
	color: green;
}
.impact.down {
	line-height: 10px;
	color: red;
}
.impact .up {
	border: solid green;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}
.impact .down {
	border: solid red;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}
.impact.risk .up {
	border: solid red;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
}
.impact.risk .down {
	border: solid green;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 3px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}
