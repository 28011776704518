.header {
	width: 100%;
	padding: 10px 15px;
	background-color: rgba(0, 40, 88, 0.65);
	border: 1px solid #fff;
	border-radius: 5px;
	color: #fff;
}

.event-body {
	width: 100%;
	background-color: rgba(0, 40, 88, 0.65);
	border: 1px solid #fff;
	border-radius: 5px;
	padding: 10px 15px;
	margin-top: 15px;
	color: #fff;
	height: 150px;
	overflow: auto;
	white-space: pre-wrap;
}

.event-options {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	color: #fff;
}

.option {
	background-color: #002858;
	width: 32%;
	padding: 20px;
	height: 250px;
	border: 1px solid #fff;
	border-radius: 5px;
	overflow-y: auto;
}

.option.selected {
	background-color: #fdba0b;
	border: none;
}

.option-title {
	margin-bottom: 10px;
}

.confirm-button {
	width: 100%;
	border-radius: 7px;
	border: none;
	background-color: #fdba0b;
	margin-top: 20px;
	padding: 20px;
	color: #fff;
	font-size: 1rem;
}

/* single option view */

.probability,
.pros,
.cons {
	background-color: rgba(0, 40, 88, 0.85);
	width: 33%;
	padding: 15px 10px 10px 15px;
	border: 1px solid #fff;
	border-radius: 5px;
	max-height: 250px;
	overflow: auto;
}

.box-title {
	margin-bottom: 10px;
}

.probability .percentage {
	text-align: center;
	font-size: 90px;
	color: #fdba0b;
	font-weight: 700;
}

.probability p {
	margin: 0;
	text-align: center;
}

.pros p,
.cons p {
	font-weight: 300;
	margin: 10px 0;
}

.pros ul,
.cons ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.pros li,
.cons li {
	margin-top: 5px;
	text-indent: -12px;
}

.pros li:before,
.cons li:before {
	content: '- ';
}

.g-list {
	color: #009152;
}

.r-list {
	color: red;
}

/* event cards */

.cards-header {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.cards-header .cards-title {
	color: #fff;
	font-size: 35px;
	font-weight: 600;
}

.cards-header .cards-probability {
	background-color: rgba(0, 40, 88, 0.5);
	font-size: 70px;
	font-weight: 900;
	color: #fdba0b;
	text-align: center;
	border: 1px solid #fff;
	border-radius: 5px;
	padding-left: 10px;
	padding-right: 10px;
}

.card-grid{
	display: grid;
	grid-template-columns: repeat(5, 191.5px);
	grid-gap: 10px;
	margin-top: 20px;
}

.card-grid .card{
	background-color: #002858;
	color: #fff;
	text-align: center;
	height: 200px;
	border: 1px solid #fff;
	border-radius: 5px;
	font-size: 70px;
	font-weight: 600;
}

.card-grid .card img{
	display: block;
	margin: 20px auto;
	max-width: 50px;
}

.card-grid .card.selected{
	background-color: #fff;
	color: #005B98;
	font-size: 50px;
	font-weight: 700;
	opacity: 1;
}

.card-grid .card.selected img{
	margin: 0 auto;
	max-width: 100px;
}

.card.show {
	opacity: 0.5;
}

.card.clicked {
	border: 4px solid #fdba0b;
}


.option-wrapper-container {
	display: flex;
	align-items: center;
}
.selected-option {
	font-size: 24px;
    color: #FFFFFF;
    margin-right: 55px;
    font-weight: bold;
}