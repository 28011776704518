.AuthorizationContainer {
	width: 100%;
	height: 100%;
}
.NotAuthorizedContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -25px;
	margin-left: -250px;
	width: 500px;
	height: 50px;
	text-align: center;
}
.NotAuthorizedContainer .NotAuthorizedText {
	color: #002955;
	font-weight: 700;
}

.NotAuthorizedContainerLoaderImg {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -100px;
	margin-left: -250px;
	width: 500px;
	height: 200px;
	text-align: center;
}
.loaderContainer {
	width: 200px;
	height: 200px;
	margin: 0 auto;
}
.loaderContainer img {
	width: 100%;
	height: 100%;
}


.avatar-wrapper {
	width: 50px;
	height: 50px;
	background-color: #FFF;
	border-radius: 25px;
	overflow: hidden;
}
.avatar-wrapper-2 {
	width: 30px;
	height: 30px;
	background-color: #FFF;
	border-radius: 15px;
	overflow: hidden;
}

.avatar-wrapper.player_1,
.avatar-wrapper-2.player_1 {
	background-color: #FFD700;
	border: none;
}
.avatar-wrapper.player_2,
.avatar-wrapper-2.player_2 {
	background-color: #0084ff;
	border: none;
}
.avatar-wrapper.player_3,
.avatar-wrapper-2.player_3 {
	background-color: #ffffff;
	border: 1px solid #aaaaaa;
}
.avatar-wrapper.player_4,
.avatar-wrapper-2.player_4 {
	background-color: #1fbd00;
	border: none;
}
.avatar-wrapper.player_5,
.avatar-wrapper-2.player_5 {
	background-color: #ff0000;
	border: none;
}
.avatar-wrapper.player_6,
.avatar-wrapper-2.player_6 {
	background-color: #aaaaaa;
	border: none;
}
