.boardContainer.rtl {
    direction: rtl;
    text-align: start;
}
.headerWrapper.rtl {
    direction: rtl;
}

.boardContainer.rtl .boardWrapper {
    border-right: 1px solid #ffffff;
    border-left: none !important;
}
.boardContainer.rtl .potential-arrow {
    right: -7px;
    left: auto !important;
}
.boardContainer.rtl .performer-arrow {
    left: 0px;
    right: auto !important;
    transform: rotate(180deg);
}

.boardContainer.rtl .boardWrapper::before {
    right: 0;
    left: auto !important;
}
.boardContainer.rtl .boardWrapper::after {
    left: 0;
    right: auto !important;
}

.boardContainer.rtl .time {
    margin-left: 22px;
    margin-right: 0px !important;
}
.boardContainer.rtl .time p, 
.boardContainer.rtl .currency p {
    margin-right: 15px;
    margin-left: 0px !important;
}

.headerWrapper.rtl .image-wrapper {
    margin-left: 10px;
    margin-right: 0px !important;
}
.headerWrapper.rtl .details-wrapper {
    text-align: start;
}
.headerWrapper.rtl .result-nav .item-nav .item p {
    margin-right: 15px;
    margin-left: 0px;
}
.headerWrapper.rtl .result-nav .item-nav .item + .item {
    margin-right: 30px;
    margin-left: 0px;
}
.headerWrapper.rtl .header-container .team-name-nav {
    border-left: 1px solid #d5d5d5;
    border-right: none;
}
.headerWrapper.rtl .header-container .team-nav {
    border-left: 1px solid #d5d5d5;
    border-right: none;
    padding-right: 0px;
    padding-left: 15px;
}

.languagesList.rtl {
    right: -6px;
    left: auto !important;
}